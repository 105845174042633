import React, {useState, useEffect} from 'react';
import TimeTracker from '../../../node_modules/react-time-tracker/lib/index';
import {isBrowser} from "../../services/auth"
import { useContainerContext } from "../../store/ContainerContext";
//import { useBeforeunload } from 'react-beforeunload';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';

import { UserTracking } from "../../queries/api_links";

const TimeTrackerComp = (props) => {
    const context = useContainerContext();
    const { user_tracking, preview_tab } = context.state;
    const { UserTrackingDispatch } = context.actions;

    // useEffect(() => {
    //   window.addEventListener('beforeunload', alertUser)
    //   //window.addEventListener('unload', handleTabClosing)
    //   return () => {
    //       window.removeEventListener('beforeunload', alertUser)
    //       //window.removeEventListener('unload', handleTabClosing)
    //   }
    // },[])
  
    // const handleTabClosing = () => {
    //   // var tab_name = props.TabName, post_data = {};
    //   // post_data = {...user_tracking, PageViews:{...user_tracking.PageViews}};
    //   // var res = UserTracking(post_data).then(res => {
    //   //   //console.log("user_tracking_res", res)
    //   //   if(res && res.status === 200){
    //   //       UserTrackingDispatch({...user_tracking, PageViews:{}});
    //   //   }
    //   // }); 
    // }
      
    // const alertUser = (event) => {
    //     event.preventDefault();
    //     event.returnValue = ''
    //     console.log("user_tracking 1", user_tracking)
    //     var tab_name = props.TabName, post_data = {};
    //     post_data = user_tracking;
    //     console.log("user_tracking post_data", post_data)
    //     var res = UserTracking(post_data).then(res => {
    //       //console.log("user_tracking_res", res)
    //       if(res && res.status === 200){
    //           UserTrackingDispatch({...user_tracking, PageViews:{}});
    //       }
    //     });   
        
    //     // confirmAlert({
    //     //   title: 'Confirm to submit',
    //     //   message: 'Are you sure to do this.',
    //     //   buttons: [
    //     //     {
    //     //       label: 'Yes',
    //     //       onClick: () => alert('Click Yes')
    //     //     },
    //     //     {
    //     //       label: 'No',
    //     //       onClick: () => alert('Click No')
    //     //     }
    //     //   ]
    //     // });
        
    // }

    //console.log("alertUser", alertUser);

    const onSave = (totalTime) => {
      
      var tab_name = props.TabName, post_data = {};
      var seconds = parseFloat(totalTime / 1000).toFixed(2);
      
      var get_prev_tab_value = 0;
      Object.keys(user_tracking.PageViews).map((key, index) => {
        if(key === tab_name) { 
          get_prev_tab_value = user_tracking.PageViews[key];
        }
      });
      var append_time = parseFloat(seconds) + get_prev_tab_value;

      UserTrackingDispatch({...user_tracking, PageViews:{...user_tracking.PageViews, [tab_name]: parseFloat(append_time.toFixed(2))}})


      // if(preview_tab.next_tab && preview_tab.next_tab === "last"){
      //   post_data = {...user_tracking, PageViews:{...user_tracking.PageViews, [tab_name]: parseFloat(append_time.toFixed(2))}};

      //   //console.log("post_data", post_data);
        
      //   if(user_tracking.PresentationType === "Preval Presentation"){
      //     if(user_tracking.PageViews && user_tracking.PageViews.Intro){
      //         var res = UserTracking(post_data).then(res => {
      //             if(res && res.status === 200){
      //                 UserTrackingDispatch({...user_tracking, PageViews:{}});
      //             }
      //         });
      //     } 
      //   } else{
      //     UserTrackingDispatch({...user_tracking, PageViews:{...user_tracking.PageViews, [tab_name]: parseFloat(append_time.toFixed(2))}})
      //   }
        
      // } else{
      //   UserTrackingDispatch({...user_tracking, PageViews:{...user_tracking.PageViews, [tab_name]: parseFloat(append_time.toFixed(2))}})
      // }

    }

    //console.log("user_tracking 2", user_tracking);

    return (
      <div>
        {/* Your component code will go here */}
        {isBrowser() && <TimeTracker onSave={onSave} pause={false} />}
      </div>
    )

    
}

export default TimeTrackerComp