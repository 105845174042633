import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import dateFormat from 'dateformat';
import SEO from "../../components/seo"

import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import PhotoImg1 from "../../assests/images/photo1.jpg";
import PhotoImg2 from "../../assests/images/photo2.jpg";

import {config} from '../../components/Common/constant';
import CampaignPlan from '../../components/modules/campaign_plan';

import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {

    const context = useContainerContext();
    const { property_data} = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('campaign-plan');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);
  
    return <>    
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
    <div className="pre-wrapper">
      
      <Header />

      <div className="section-wrapper custom_present_inner pt-0">
        {property_data && <CampaignPlan property_data={property_data} /> }
      </div>
      
      <NavLink tab_name="Plan" prevLink={config.custom_prop_datetime} nextLink={config.custom_teams} />

    </div>
  </>
}

export default PresentationPage
